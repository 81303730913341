import * as React from 'react';
import { SwiperSlide } from 'swiper/react';

import { useSelector, useDevice } from 'services/hooks';
import { userUpcomingClassesData } from 'selectors';

import { AccountNavigationUpcomingClass } from '../AccountNavigationUpcomingClass';
import { CarouselContainer, ExtendedSwiper, ExtendedText, ClassesWrapper, Progressbar,
  ProgressbarContainer } from './styled';

export const AccountNavigationUpcomingClasses: React.FC = () => {
  const { isMobile } = useDevice();
  const slides = useSelector(userUpcomingClassesData) || [];
  const [barNumber, setActiveBar] = React.useState(0);

  const swiperParams = {
    className: 'carousel',
    loopFillGroupWithBlank: false,
    grabCursor: true,
    breakpoints: {
      300: {
        slidesPerView: 1.25,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 12,
      },
    },
  };

  return (
    <ClassesWrapper>
      {slides && slides.length !== 0 && (
        <>
          <ExtendedText variant={['gray', 'medium']}>
            Booked classes ({slides.length})
          </ExtendedText>
          <CarouselContainer>
            <ExtendedSwiper
              onActiveIndexChange={({ activeIndex }) => {
                setActiveBar(activeIndex);
              }}
              {...swiperParams}
            >
              <SwiperSlide className="carousel__placeholder" />
              {slides.map((item, i) => (
                <SwiperSlide className="carousel__slide" key={`${item.id}_${i}`}>
                  <AccountNavigationUpcomingClass item={item} />
                </SwiperSlide>
              ))}
              {slides.length > 1 && (
                <SwiperSlide className="carousel__placeholder" />
              )}
            </ExtendedSwiper>
          </CarouselContainer>
          {isMobile && (
            <ProgressbarContainer>
              {slides?.map((el, index) => (
                <Progressbar
                  active={index === barNumber}
                  key={`${el.id}_${index}`}
                />
              ))}
            </ProgressbarContainer>
          )}
        </>
      )}
    </ClassesWrapper>
  );
};
