import * as i from 'types';
import * as React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { Text } from 'common/typography';
import { ClassBookingLabel } from 'modules/booking';

import { formatDay, formatWrittenDate, formatTime } from 'services';
import InstructorIcon from 'vectors/instructorIcon.svg';
import LocationMarker from 'vectors/locationMarker.svg';
import DurationIcon from 'vectors/duration.svg';

import { ImageContentContainer, CarouselSlide, CarouselImage, CarouselContentContainer, IconWrapper,
  HorizontalLine, CarouselContent, Row, LabelContainer } from './styled';

export const AccountNavigationUpcomingClass = ({ item }: Props) => {
  const { navigate } = useI18next();
  const description = item?.classDescription || '';
  const matches = description.match(/\[([^\]]+)\]/g) || [];
  const values = matches.map((match) => match.slice(1, -1));
  const imageUrl = values[0] || '';

  return (
    <CarouselSlide onClick={() => navigate(`/class/${item.classId}`)}>
      <LabelContainer>
        <ClassBookingLabel item={item} />
      </LabelContainer>
      <CarouselImage imageUrl={imageUrl}>
        <ImageContentContainer>
          <Text variant="white" $size={30} $lineHeight="40">
            {item.classType}
          </Text>
          <Text variant="white" $size={16} $lineHeight="24">
            {formatTime(item.classDate)}
          </Text>
        </ImageContentContainer>
      </CarouselImage>
      <CarouselContentContainer>
        <Text $size={16} $lineHeight="24">
          {formatDay(item.classDate)} - {formatWrittenDate(item.classDate)}
        </Text>
        <HorizontalLine />
        <CarouselContent>
          <Row>
            <IconWrapper>
              <LocationMarker />
            </IconWrapper>
            <Text $size={12}>
              {item.siteName}
            </Text>
          </Row>
          <Row>
            <IconWrapper>
              <InstructorIcon />
            </IconWrapper>
            <Text $size={12}>
              {item.instructorName}
            </Text>
          </Row>
          <Row>
            <IconWrapper>
              <DurationIcon />
            </IconWrapper>
            <Text $size={12}>
              {item.classLength}
            </Text>
          </Row>
        </CarouselContent>
      </CarouselContentContainer>
    </CarouselSlide>
  );
};

type Props = {
  item: i.Serie;
}
